import { animate, AnimatePresence, motion, useMotionTemplate, useMotionValue, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import LastFrameRecuceMo from "../LastFrameRecuceMo"
import Image from "components/media/ImageCLD"
import { HIDDEN_PHASE, useSwitcher, VOID_PHASE } from "../switchcontext"
import { THE_WATCH, useExp } from "../expcontext"
import { THE_END } from "../expcontext"

const Container = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const Picontainer = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default function ReduceMoEndScreen({ sources, video_fallback }) {
  const console = useConsole()

  const { scenario, currentChapter, endVideoProg, currentStep } = useExp()
  const { resetPhase } = useSwitcher()

  const opacity = useMotionValue(0)
  const bright = useMotionValue(100)

  const filter = useMotionTemplate`brightness(${bright}%) blur(15px)`

  function onStepChange(s) {
    if (s === THE_END) {
      opacity.set(0)
      bright.set(100)
      animate(opacity, 1, { duration: 0.5, delay: 1.2 }).then(() => endVideoProg.set(1))
      animate(bright, 70, { duration: 2 })
    }
  }
  useMotionValueEvent(currentStep, "change", onStepChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      opacity.set(0)
      bright.set(100)
    } else if (p === VOID_PHASE) {
      if (currentChapter.get() === THE_END) {
        animate(opacity, 1, { duration: 0.5, delay: 1.2 }).then(() => endVideoProg.set(1))
        animate(bright, 70, { duration: 2 })
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  return (
    // <AnimatePresence>
    <Container>
      <Picontainer>
        <Image sources={video_fallback} />
      </Picontainer>
      <Picontainer style={{ opacity, zIndex: 1, filter }}>
        {/* <Picontainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}> */}
        <LastFrameRecuceMo sources={sources} />
      </Picontainer>
    </Container>
    // </AnimatePresence>
  )
}
