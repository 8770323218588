import { createContext, useContext, useLayoutEffect } from "react"
import { useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import { useExp, PAGES, CHAP_KEYS, THE_WATCH_HUB_FEAT_1, THE_WATCH_HUB } from "./expcontext"
import { useViewport } from "contexts/Viewport"

export const Context = createContext()

export const SWITCH_PHASE = -6
export const LOADED_PHASE = -5
export const VOID_PHASE = -4
export const HIDDEN_PHASE = -3
export const BP_CHANGE = -2
export const INIT_PHASE = -1

export const SwitcherProvider = ({ seqlogic, children }) => {
  const console = useConsole()
  const env = useEnv()

  const { scenario, targetScenario, targetChapter, currentStep, revealProg, isRevealed, currentChapter, device, theWatchSequences, hubFra } = useExp()

  const resetPhase = useMotionValue(VOID_PHASE)

  async function loadNextFrames(s, c) {
    console.verbose("++++ loadNextFrames", s, c)
    const toLoad = seqlogic[s].get(CHAP_KEYS[PAGES.indexOf(c)]).map(c => c.loadFrames(device.get()))
    return Promise.all(toLoad)
  }

  async function loadFramesForDevice(s, c) {
    const k = CHAP_KEYS[PAGES.indexOf(c)]
    const keyForLoad = k === "introduction" ? "the_watch" : k
    const toLoad = seqlogic[s].get(keyForLoad).map(c => c.loadFrames(device.get()))
    return Promise.all(toLoad)
  }

  async function onPhaseChange(p) {
    if (p === SWITCH_PHASE) {
      const [s, c] = resetPhase.prev.split(".")
      const scen = Number(s)
      const chap = Number(c)
      loadFramesForDevice(scen, chap).then(() => {
        currentChapter.set(chap)
        currentStep.set(chap)
        if (chap >= THE_WATCH_HUB_FEAT_1) {
          revealProg.set(0)
          isRevealed.set(true)
        }
        if (chap === THE_WATCH_HUB_FEAT_1) {
          hubFra[s][device.get()] = theWatchSequences[s][THE_WATCH_HUB].reduce((acc, x) => acc.concat(x.seqloader[device.get()]), [])
        }
        scenario.set(scen)
        targetScenario.set(scen)
        // targetChapter.set(currentChapter.get())
        targetChapter.set(-1)

        resetPhase.set(VOID_PHASE)
      })
    } else if (p === HIDDEN_PHASE) {
      if (targetChapter.get() >= THE_WATCH_HUB_FEAT_1) {
        revealProg.set(0)
        isRevealed.set(true)
      }
      loadNextFrames(scenario.get(), targetChapter.get()).then(() => {
        currentChapter.set(targetChapter.get())
        currentStep.set(targetChapter.get())
        targetChapter.set(-1)
        setTimeout(() => resetPhase.set(LOADED_PHASE), 200)
        // resetPhase.set(LOADED_PHASE)
      })
    } else if (p === BP_CHANGE) {
      loadFramesForDevice(scenario.get(), currentChapter.get()).then(() => {
        resetPhase.set(VOID_PHASE)
      })
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  function onDevice(d) {
    if (scenario.get() >= 0 && currentChapter.get() >= 0) {
      resetPhase.set(BP_CHANGE)
    }
  }
  useMotionValueEvent(device, "change", onDevice)

  return <Context.Provider value={{ resetPhase }}>{children}</Context.Provider>
}

export const useSwitcher = () => useContext(Context)
