import { useLayoutEffect } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { buttonReset, buttonContrastMode } from "css/buttons"
import { bold } from "css/text"
import getMediaQuery from "css/breakpoints"

import { useConsole } from "contexts/Console"

import { useStory } from "components/stories/context"
import { Icon } from "components/icon/Icon"
import { useExp } from "./../expcontext"
import Button from "components/button/Button"

const StoryChip = styled(motion(Button))`
  inset-block-end: 5%;
  z-index: 10;
  margin-block-start: 20px;
  pointer-events: all;

  background: rgba(33 33 33 / 0.2) !important;

  backdrop-filter: blur(8px) !important;

  transition: background 0.3s !important;

  @media (hover: hover) {
    &:hover {
      background: rgba(33 33 33 / 0.35) !important;
    }
  }
`

export default function FocusStoryLauncher({ feature_id, label, story, ...props }) {
  const console = useConsole()

  // TODO initiator, wasKeyDown
  const { currentInteraction } = useExp()
  const { storyOpener, storyGroups, initiator } = useStory()

  storyGroups.set(story[0].uid, story)

  function onClick() {
    initiator.set("xplit")
    storyOpener.set(`${story[0].uid}:${story[0].story_id}-0`)
    currentInteraction.set(`watch:ffeatqview:${feature_id}`)
  }

  useLayoutEffect(() => {
    storyOpener.set("")
  }, [])

  return (
    <StoryChip type='button' className='filled translucent-light' icon='none' onPress={onClick}>
      <Icon type='plus' />
      {label}
    </StoryChip>
  )
}
