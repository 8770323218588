import { useRef, useState } from "react"
import { animate, motion, useMotionTemplate, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { buttonReset, buttonContrastMode } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { useExp, CANVINDEX, RATIOS, SIZES } from "../expcontext"
import { useHub } from "./hubcontext"
import FeatureFocus from "./FeatureFocus"
import { FocusFeatureProvider } from "./focuscontext"
import { useFeature } from "./featurecontext"
import { bold } from "css/text"
import { useViewport } from "contexts/Viewport"
import getMediaQuery from "css/breakpoints"

const AxisCont = styled(motion.div)`
  position: absolute;
  inset: 50%;
  z-index: 10;
  pointer-events: none;
`

const PinCont = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 44px;
  height: 44px;
  margin-inline-start: -22px;
  margin-block-start: -22px;
`

const PinButton = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  ${bold}
  height: 44px;
  overflow: hidden;
  border-radius: 44px;
  position: absolute;
  display: flex;
  border: none;
  z-index: 2;
  align-items: center;
  user-select: none;
  font-size: 1rem;
  pointer-events: all;
  background: rgba(33 33 33 / 0.2);
  color: white;
  backdrop-filter: blur(8px);

  transition: background 0.3s;

  &:hover {
    background: rgba(33 33 33 / 0.35);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  padding: 0;

  & > svg {
    width: 14px;
    height: 14px;
    grid-column: 2;
  }
  .bracelet & {
    left: 50%;
    transform: translateX(-50%);
  }
  .dial & {
    left: 50%;
    transform: translateX(-50%);
  }
  .design & {
    left: 50%;
    transform: translateX(-50%);
  }

  ${getMediaQuery("m")} {
    .bracelet & {
      left: 0;
      transform: unset;
    }
    .dial & {
      left: 0;
      transform: unset;
    }
    .design & {
      left: unset;
      left: 0;
      transform: unset;
    }
  }
`

const Con = styled(motion.div)`
  pointer-events: none;
  display: flex;
  align-items: center;
  padding-inline: calc(2.1875rem - 0.5rem) 2.1875rem;
`

const Label = styled(motion.span)`
  white-space: nowrap;
  margin-inline-start: 0.5rem;
`

const ConPin = styled(motion.div)`
  position: absolute;
  z-index: 2;
  inset: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
`

export const ELPOS = [
  {
    design: { a: 90, w: 281, h: 269, x: 0, y: 110, r: 0 },
    dial: { a: 5, w: 284, h: 285, x: 0, y: 98, r: 0 },
    bracelet: { a: 197, w: 290, h: 300, x: 29, y: 80, r: 0 },
  },
  {
    design: { a: 130, w: 293, h: 23, x: 48, y: -171, r: -6 },
    dial: { a: 313, w: 317, h: 38, x: -55, y: 33, r: 2 },
    bracelet: { a: 241, w: 295, h: 17, x: 38, y: 193, r: -4 },
  },
]

export const RADIUS = 280

export default function Feature({
  feature_id,
  label,
  paragraph,
  title,
  micro_seqloader,
  transition_seqloader,
  index,
  isDragging,
  curFeature,
  quick_view,
  targetFeatStep,
  progress,
}) {
  const console = useConsole()
  const { width, height } = useViewport()

  const { pagisible, device, currentInteraction } = useExp()
  const { max } = useHub()
  const { targetFeatFocus, isFocused } = useFeature()

  const btnRef = useRef()
  const triggerer = useRef(false)

  const [statuts, setStatus] = useState(curFeature.get() === index ? "opened" : "closed")

  const globalRotation = useTransform(progress, [0, max], [0, 360])
  const normAngle = useTransform([globalRotation, device], ([r, d]) => (((ELPOS[d][feature_id].a + r) % 360) - 180) * -1)

  const elWidthCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nw = RATIOS[device.get()] < w / h ? w : h * RATIOS[device.get()] // mode cover
    return ELPOS[d][feature_id].w * (nw / SIZES[d].width)
  })
  const elHeightCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nh = RATIOS[device.get()] < w / h ? w / RATIOS[device.get()] : h // mode cover
    return ELPOS[d][feature_id].h * (nh / SIZES[d].height)
  })
  const elXCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nw = RATIOS[d] < w / h ? w : h * RATIOS[d] // mode cover
    return ELPOS[d][feature_id].x * (nw / SIZES[d].width)
  })
  const elYCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nh = RATIOS[d] < w / h ? w / RATIOS[d] : h // mode cover
    return ELPOS[d][feature_id].y * (nh / SIZES[d].height)
  })

  const elw = useTransform(elWidthCorrected, w => w * 2)
  const elh = useTransform(elHeightCorrected, h => h * 2)
  const elx = useTransform([normAngle, elWidthCorrected], ([nr, ew]) => ew * Math.cos((nr * Math.PI) / 180) + ew)
  const ely = useTransform([normAngle, elHeightCorrected], ([nr, eh]) => eh * Math.sin((nr * Math.PI) / 180) + eh)
  const elscale = useTransform(normAngle, [-180, -90, 0, 90, 180], [1, 0.85, 1, 1, 1])
  const blur = useTransform(normAngle, [-180, -90, 0, 90, 180], [0, 2, 0, 0, 0])
  const filter = useMotionTemplate`blur(${blur}px)`
  const zIndex = useTransform(normAngle, n => (n < 0 ? CANVINDEX - 1 : CANVINDEX + 1))
  const opacity = useMotionValue(1)
  const elipstransform = useTransform(
    [elXCorrected, elYCorrected, device],
    ([ex, ey, d]) => `translateX(calc(-50% + ${ex}px)) translateY(calc(-50% + ${ey}px)) rotateZ(${ELPOS[d][feature_id].r}deg)`
  )
  const eltransform = useTransform(
    [elx, ely, elscale, device],
    ([x, y, sc, d]) => `translate3d(${x}px,${y}px,0) rotateZ(${-ELPOS[d][feature_id].r}deg) scale(${sc})`
  )

  function onClick(e) {
    if (curFeature.get() !== index) {
      targetFeatStep.set(index)
      return
    }
    triggerer.current = true
    targetFeatFocus.set(index)
    isFocused.set(true)
    pagisible.set(false)
    currentInteraction.set(`watch:${index}`)
  }

  function onTargetFeatFocus(v) {
    if (v >= 0) return
    if (triggerer.current) {
      setTimeout(() => btnRef.current.focus(), 40)
    }
    triggerer.current = false
  }
  useMotionValueEvent(targetFeatFocus, "change", onTargetFeatFocus)

  function onFeatFocus(b) {
    btnRef.current.disabled = b
    animate(opacity, b ? 0 : 1, { duration: 0.35 })
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  function onCurFeat(f) {
    if (f === index) {
      setStatus("opened")
      btnRef.current.focus()
    } else {
      setStatus("closed")
    }
  }
  useMotionValueEvent(curFeature, "change", onCurFeat)

  function onDragging(b) {
    if (b) {
      setStatus("closed")
    } else {
      if (curFeature.get() === index) {
        setStatus("opened")
      }
    }
  }
  useMotionValueEvent(isDragging, "change", onDragging)

  const variants = {
    opened: { width: "auto", transition: { duration: 0.35, ease: "easeIn" } },
    closed: { width: "44px", transition: { duration: 0.15, ease: "easeOut" } },
  }

  const pinVariants = {
    hidden: { scale: 0.5, opacity: 0, transition: { duration: 0 } },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } },
  }

  return (
    <>
      <FocusFeatureProvider>
        <FeatureFocus
          transition_seqloader={transition_seqloader}
          micro_seqloader={micro_seqloader}
          index={index}
          feature_id={feature_id}
          label={label}
          paragraph={paragraph}
          title={title}
          curFeature={curFeature}
          quick_view={quick_view}
        />
      </FocusFeatureProvider>
      <ConPin key={`pi-${feature_id}`} variants={pinVariants} initial='hidden' animate='visible' exit='hidden' style={{ zIndex }}>
        <AxisCont className={feature_id} style={{ transform: elipstransform, width: elw, height: elh, opacity }}>
          <PinCont style={{ transform: eltransform }}>
            <PinButton type='button' ref={btnRef} onClick={onClick} variants={variants} animate={statuts} style={{ filter }} aria-label={label}>
              <Con variants={{ opened: { x: "0px" }, closed: { x: "-12.5px" } }}>
                <Icon type='plus' />
                <Label variants={{ opened: { opacity: 1 }, closed: { opacity: 0 } }}>{label}</Label>
              </Con>
            </PinButton>
          </PinCont>
        </AxisCont>
      </ConPin>
    </>
  )
}
