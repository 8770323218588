import { useEffect, useRef, useState, useLayoutEffect } from "react"
import { animate, AnimatePresence, motion, useMotionTemplate, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import getMediaQuery from "css/breakpoints"

import VideoLauncher from "components/video-launcher/VideoLauncher"
import { useExp, THE_MOVEMENT, THE_MOVEMENT_CLOSING, THE_END, THE_END_ENDED } from "../expcontext"
import EndVideo from "./EndVideo"
import { HIDDEN_PHASE, useSwitcher } from "../switchcontext"
import { getVideo } from "../utils"
import Previous from "./Previous"
import { PAGES } from "../expcontext"
import ReduceMoEndScreen from "./ReduceMoEndScreen"

const DUR = 2

const VidCont = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
`

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-rows: 40px 1fr 5%;
  z-index: 5;

  ${getMediaQuery("m")} {
    grid-template-rows: 40px 1fr 5%;
  }
`

const Vidniet = styled(motion.div)`
  width: 85%;
  height: 50%;
  border-radius: 20px;
  grid-row: 2;
  grid-column: 1;
  justify-self: center;
  align-self: center;
  display: grid;
  justify-items: center;
  align-items: center;

  & > button,
  & > video {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }

  ${getMediaQuery("m")} {
    width: max(600px, min(53%, 900px));
    height: auto;
  }
`

const Vid = styled(motion.video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 20px;
  filter: drop-shadow(0px 2px 30px rgba(0 0 0 / 0.4));
`

function EndVidLoop({ readyToReduce }) {
  const console = useConsole()
  const env = useEnv()

  const { endVideoLoop, endVideoPlayer, prefersReducedMotion, helmet } = useExp()

  const { src, poster } = getVideo({ src: endVideoLoop[0].public_id }, env)({ width: endVideoLoop[0]?.width, quality: "auto:best" })

  const [vidsible, isVidsible] = useState(false)

  function onReadyReduce(b) {
    isVidsible(b)
  }
  useMotionValueEvent(readyToReduce, "change", onReadyReduce)

  const variants = {
    visible: { opacity: 1, transform: "translate3d(0, 0, 0)", z: 0, transition: { type: "tween", duration: 2 } },
    hidden: { opacity: 0, transform: "translate3d(0, 20%, 0)", z: 0, transition: { duration: 0 } },
  }

  function onChange(v) {
    helmet.set(Date.now())
  }

  const ref = useRef(null)
  useLayoutEffect(() => {
    if (!vidsible) return
    setTimeout(() => ref.current.querySelector("button").focus(), 40)
  }, [vidsible])

  return (
    <AnimatePresence>
      {vidsible ? (
        <Container ref={ref} initial='hidden' animate='visible' exit='hidden'>
          <Vidniet variants={variants}>
            <Vid
              poster={poster}
              controls={false}
              controlsList='nofullscreen nodownload'
              disablePictureInPicture
              disableRemotePlayback
              loop={true}
              autoPlay={!prefersReducedMotion}
              muted={true}
              playsInline
              preload='auto'
            >
              <source src={src} type='video/mp4' />
            </Vid>
            <VideoLauncher icon='play' video_player_cld={endVideoPlayer} onChange={onChange} />
          </Vidniet>
        </Container>
      ) : null}
    </AnimatePresence>
  )
}

function EndUi() {
  const { targetChapter, currentStep } = useExp()

  const [isVisi, setIsVisi] = useState(currentStep.get() === THE_END)

  function onPreviousClick() {
    targetChapter.set(PAGES[2])
  }

  function onStepChange(s) {
    if (s === THE_END) {
      setIsVisi(true)
    }
  }
  useMotionValueEvent(currentStep, "change", onStepChange)

  return <AnimatePresence>{isVisi ? <Previous onClickCB={onPreviousClick} /> : null}</AnimatePresence>
}

function TheEndContent({ scenarSelected }) {
  const console = useConsole()

  const { theEnd, currentStep, unrevealProg, closeProg, endVideoProg, targetChapter, currentChapter, prefersReducedMotion } = useExp()
  const { resetPhase } = useSwitcher()

  const videRef = useRef()
  const isVideoPlay = useTransform(unrevealProg, p => p >= 0.2)
  const readyToReduce = useTransform(endVideoProg, p => p >= 0.6)

  const bright = useMotionValue(100)
  const blur = useMotionValue(0)
  const filter = useMotionTemplate`brightness(${bright}%)`
  const filterBlur = useMotionTemplate`blur(${blur}px)`
  const background = useTransform(endVideoProg, p => (p >= 0.3 ? "rgb(89,89,89)" : "transparent"))

  function playAnim() {
    animate(closeProg, 1, { duration: DUR }).then(() => currentStep.set(THE_END))
    animate(unrevealProg, 100, { delay: DUR * 0.8, duration: DUR / 3 })
  }
  function setAnim() {
    closeProg.set(1)
    unrevealProg.set(100)
  }

  function onStepChange(s) {
    if (s === THE_MOVEMENT_CLOSING) {
      playAnim()
    }
    if (s === THE_END) {
      currentChapter.set(THE_END)
      setAnim()
    }
  }
  useMotionValueEvent(currentStep, "change", onStepChange)

  function onIsVideoPlay(b) {
    if (b && videRef.current) {
      videRef.current.play()
    }
  }
  useMotionValueEvent(isVideoPlay, "change", onIsVideoPlay)

  function onReadyReduce(b) {
    if (b) {
      animate(bright, 70, { duration: 2 })
      animate(blur, 15, { duration: 2 })
      currentStep.set(THE_END_ENDED)
    }
  }
  useMotionValueEvent(readyToReduce, "change", onReadyReduce)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() <= THE_END) {
        reset()
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  function reset() {
    videRef.current?.stop()
    videRef.current?.rewind()
    closeProg.set(0)
    unrevealProg.set(0)
    bright.set(100)
    blur.set(0)
    endVideoProg.set(0)
  }

  useEffect(() => {
    //    reset()
    if (currentStep.get() === THE_MOVEMENT_CLOSING) {
      playAnim()
    } else if (currentStep.get() === THE_END) {
      setAnim()
    }

    return reset
  }, [])

  return (
    <>
      {prefersReducedMotion ? (
        <ReduceMoEndScreen sources={theEnd[scenarSelected].video} video_fallback={theEnd[scenarSelected].video_fallback} />
      ) : (
        <VidCont style={{ filter, background }}>
          <EndVideo ref={videRef} sources={theEnd[scenarSelected].video} filterBlur={filterBlur} />
        </VidCont>
      )}
      <EndVidLoop readyToReduce={readyToReduce} />
      <EndUi />
    </>
  )
}

export default function TheEnd({ scenarSelected }) {
  const console = useConsole()

  const { currentStep } = useExp()

  const [isReady, setIsReady] = useState()

  function onStepChange(c) {
    if (c >= THE_MOVEMENT) {
      setIsReady(true)
    } else {
      setIsReady(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onStepChange)

  return <AnimatePresence>{isReady ? <TheEndContent scenarSelected={scenarSelected} /> : null}</AnimatePresence>
}
