import { animate, motion, useMotionValue, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import LastFrameRecuceMo from "./LastFrameRecuceMo"
import Image from "components/media/ImageCLD"
import { HIDDEN_PHASE, useSwitcher, VOID_PHASE } from "./switchcontext"
import { THE_INTRODUCTION, THE_WATCH, useExp } from "./expcontext"

const Container = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const Picontainer = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default function ReduceMoScreen({ sources, video_fallback, index }) {
  const console = useConsole()

  const { scenario, currentChapter, targetChapter, videoProg, pagisible, currentStep } = useExp()
  const { resetPhase } = useSwitcher()

  const opacity = useMotionValue(0)

  function onScenarioChange(i) {
    if (i === index) {
      animate(opacity, 1, { duration: 0.5, delay: 1.2 }).then(() => currentStep.set(THE_WATCH))
      pagisible.set(true)
    }
  }
  useMotionValueEvent(scenario, "change", onScenarioChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() === THE_INTRODUCTION) {
        if (scenario.get() === index) {
          opacity.set(0)
        }
      }
    } else if (p === VOID_PHASE) {
      if (currentChapter.get() === THE_INTRODUCTION) {
        if (scenario.get() === index) {
          animate(opacity, 1, { duration: 0.5, delay: 1.2 }).then(() => currentStep.set(THE_WATCH))
          videoProg.set(1)
        }
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  return (
    <Container>
      <Picontainer>
        <Image sources={video_fallback} />
      </Picontainer>
      <Picontainer style={{ opacity }}>
        <LastFrameRecuceMo sources={sources} />
      </Picontainer>
    </Container>
  )
}
