import { createContext, useContext, useLayoutEffect, useRef } from "react"
import { useMotionTemplate, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { useViewport } from "contexts/Viewport"

import SequenceLoader, { DESK, MOB } from "./SequenceLoader"
import useConstant from "hooks/useConstant"
import { useXplit } from "./context"

export const MODEL_A = 0
export const MODEL_B = 1

export const FEAT_1 = "design"
export const FEAT_2 = "dial"
export const FEAT_3 = "bracelet"

export const THE_INTRODUCTION = 0
export const THE_WATCH = 100
export const THE_WATCH_HUB = 110
export const THE_WATCH_HUB_FEAT_1 = 111
export const THE_WATCH_HUB_FEAT_2 = 112
export const THE_WATCH_HUB_FEAT_3 = 113
export const THE_MOVEMENT = 200
export const THE_MOVEMENT_CLOSED = 201
export const THE_MOVEMENT_SPLASH = 202
export const THE_MOVEMENT_OPENED = 203
export const THE_MOVEMENT_CLOSING = 204
export const THE_END = 300
export const THE_END_ENDED = 301

export const SIZES = [
  { width: 800, height: 1420 },
  { width: 1920, height: 1080 },
]

export const RATIOS = [SIZES[0].width / SIZES[0].height, SIZES[1].width / SIZES[1].height]

export const CANVINDEX = 6

export const PAGES = [THE_INTRODUCTION, THE_WATCH_HUB_FEAT_1, THE_MOVEMENT_CLOSED, THE_END]

export const FEATURES = [THE_WATCH_HUB_FEAT_1, THE_WATCH_HUB_FEAT_2, THE_WATCH_HUB_FEAT_3]

export const FEATS_MAP = {
  design: THE_WATCH_HUB_FEAT_1,
  dial: THE_WATCH_HUB_FEAT_2,
  bracelet: THE_WATCH_HUB_FEAT_3,
}

export const CHAP_KEYS = ["introduction", "the_watch", "the_movement", "the_end"]

export const Context = createContext()

export const ExpXplitProvider = ({ data, children }) => {
  const console = useConsole()
  const env = useEnv()

  const canCtx = useRef()
  const { xp_query, xp_updateQuery } = useXplit()
  const [sc = -1, ch = -1] =
    (xp_query.get() || "")
      .split("/")
      ?.filter(v => !!v)
      ?.map(Number) ?? []
  const { width } = useViewport()

  const scenario = useMotionValue(sc)
  const targetScenario = useMotionValue(-1)
  const currentChapter = useMotionValue(ch)
  const targetChapter = useMotionValue(-1)
  const targetChapterTransi = useMotionValue(-1)
  const currentStep = useMotionValue(-1)
  const pagisible = useMotionValue(sc >= 0)
  const currentInteraction = useMotionValue(null)

  const device = useTransform(width, w => (w < 768 ? MOB : DESK))

  const seenSteps = useConstant(() => [false, false, false])

  const ofs = useTransform(device, d => (d === 0 ? 20 : 22))

  const revealProg = useMotionValue(width.get() * 0.92 - ofs.get())
  const isRevealed = useMotionValue(false)
  const unrevealProg = useMotionValue(0)
  const frameToDraw = useMotionValue(null)

  const videoProg = useMotionValue(0)
  const endVideoProg = useMotionValue(0)
  const closeProg = useMotionValue(0)

  const dirBlurX = useMotionValue(0)
  const dirBlurY = useMotionValue(0)

  const shared = useMotionValue(0)
  const helmet = useMotionValue(0)

  const hubFra = [
    [null, null],
    [null, null],
  ]

  const content = data

  console.verbose("\n••••••• content", content)

  const heading = content.heading
  const paragraph = content.paragraph
  const enterCtas = content.ctas_enter.map(cta => ({ ...cta, style: "filled opaque-white" }))

  const switchLabel = content.switch_label
  const introduction = { content: content.introduction, bg: content.intro_background }
  const endVideoLoop = content.end_video_loop
  const endVideoPlayer = content.end_video_player
  const nav_link = content.nav_link
  const foregrounds = []

  const { chapters: chapterLabels, instruction, focus_back, next_label } = content.dictionary[0]

  const seqlogic = [new Map(CHAP_KEYS.map(k => [k, []])), new Map(CHAP_KEYS.map(k => [k, []]))]

  const theWatchSequences = content.the_watch.reduce((acc, { opening, feature, heading, background }, i) => {
    const { sequence: openingsequence, ...openingrest } = opening
    const obj = {}

    foregrounds.push(background)

    obj[THE_WATCH] = { seqloader: new SequenceLoader({ ...openingsequence, env, console }), heading, background, nbfs: [], ...openingrest }
    seqlogic[i].get("the_watch").push(obj[THE_WATCH].seqloader)

    obj[THE_WATCH_HUB] = feature.map(f => {
      obj[THE_WATCH].nbfs.push(f.hub_sequence.number_of_frame)
      return { seqloader: new SequenceLoader({ ...f.hub_sequence, env, console }) }
    })

    for (const s of obj[THE_WATCH_HUB]) {
      seqlogic[i].get("the_watch").push(s.seqloader)
    }

    for (const { hub_sequence, transition_sequence, micro_sequence, feature_id, ...featrest } of feature) {
      obj[FEATS_MAP[feature_id]] = {
        transition_seqloader: new SequenceLoader({ ...transition_sequence, env, console }),
        micro_seqloader: new SequenceLoader({ ...micro_sequence, env, console }),
        feature_id,
        ...featrest,
      }
      seqlogic[i].get("the_watch").push(obj[FEATS_MAP[feature_id]].transition_seqloader, obj[FEATS_MAP[feature_id]].micro_seqloader)
    }
    return acc.concat([obj])
  }, [])

  const theMovementSequences = content.the_movement.reduce(
    (acc, { transition_sequence, splash_sequence, closing, closed_loop, opened_loop, background }, i) => {
      const obj = {}
      obj[THE_MOVEMENT] = {
        seqloader: new SequenceLoader({ ...transition_sequence, env, console }),
        title: content.movement_copy.title,
        paragraph: content.movement_copy.paragraph,
        background: background,
      }
      seqlogic[i].get("the_movement").push(obj[THE_MOVEMENT].seqloader)

      obj[THE_MOVEMENT_CLOSED] = { seqloader: new SequenceLoader({ ...closed_loop, env, console }) }
      seqlogic[i].get("the_movement").push(obj[THE_MOVEMENT_CLOSED].seqloader)

      obj[THE_MOVEMENT_SPLASH] = { seqloader: new SequenceLoader({ ...splash_sequence, env, console }) }
      seqlogic[i].get("the_movement").push(obj[THE_MOVEMENT_SPLASH].seqloader)

      obj[THE_MOVEMENT_OPENED] = { seqloader: new SequenceLoader({ ...opened_loop, env, console }) }
      seqlogic[i].get("the_movement").push(obj[THE_MOVEMENT_OPENED].seqloader)

      obj[THE_MOVEMENT_CLOSING] = { seqloader: new SequenceLoader({ ...closing, env, console }) }
      seqlogic[i].get("the_movement").push(obj[THE_MOVEMENT_CLOSING].seqloader)

      return acc.concat([obj])
    },
    []
  )

  const theStories = content.movement_focus

  const theEnd = content.the_end.reduce((acc, { video, link, switch_label, video_fallback }) => acc.concat([{ video, link, video_fallback, switch_label }]), [])
  const background = content.background

  function onScenario(v) {
    xp_updateQuery(v, currentChapter.get())
  }
  useMotionValueEvent(scenario, "change", onScenario)

  function onCurrentChapter(v) {
    xp_updateQuery(scenario.get(), v)
  }
  useMotionValueEvent(currentChapter, "change", onCurrentChapter)

  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")

  return (
    <Context.Provider
      value={{
        canCtx,
        revealProg,
        ofs,
        isRevealed,
        unrevealProg,
        closeProg,
        content,
        scenario,
        heading,
        paragraph,
        enterCtas,
        targetScenario,
        seenSteps,
        introduction,
        switchLabel,
        currentStep,
        currentChapter,
        currentInteraction,
        targetChapter,
        targetChapterTransi,
        frameToDraw,
        theWatchSequences,
        theMovementSequences,
        theEnd,
        videoProg,
        endVideoProg,
        chapterLabels,
        instruction,
        pagisible,
        device,
        background,
        theStories,
        seqlogic,
        endVideoLoop,
        endVideoPlayer,
        nav_link,
        focus_back,
        next_label,
        dirBlurX,
        dirBlurY,
        foregrounds,
        shared,
        prefersReducedMotion,
        hubFra,
        helmet,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useExp = () => useContext(Context)
