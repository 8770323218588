import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import { getPoster } from "./utils"
import { WIDTHS } from "components/media/ImageCLD"

export default function LastFrameRecuceMo({ sources }) {
  const console = useConsole()
  const env = useEnv()

  let portrait_index = sources?.reduce((a, v, i) => {
    if (v?.metadata?.v7_type?.[0] === "portrait") a = i
    return a
  }, -1)
  if (portrait_index < 0) {
    // no metadata found
    portrait_index = sources?.reduce((a, v, i) => {
      if (v.width / v.height <= 1) a = i
      return a
    }, -1)
  }
  if (portrait_index < 0) {
    // no ratio found
    portrait_index = 0
  }
  const landscape_index = sources.length < 2 ? portrait_index : 1 - portrait_index

  const srcSetP = WIDTHS.reduce((acc, c, i, arr) => {
    const { poster } = getPoster({ src: sources?.[portrait_index]?.public_id, frame: "so_10" }, env)({ width: c, quality: "auto:eco" })
    poster.searchParams.set("imwidth", c)
    return `${acc}${poster.href} ${c}w${i < arr.length - 1 ? ", " : ""}`
  }, "")
  const srcSetL = WIDTHS.reduce((acc, c, i, arr) => {
    const { poster } = getPoster({ src: sources?.[landscape_index]?.public_id, frame: "so_10" }, env)({ width: c, quality: "auto:eco" })
    poster.searchParams.set("imwidth", c)
    return `${acc}${poster.href} ${c}w${i < arr.length - 1 ? ", " : ""}`
  }, "")

  return (
    <picture>
      <source media='(min-width: 768px)' srcSet={srcSetL} sizes='100vw' width={sources?.[landscape_index].width} height={sources?.[landscape_index].height} />
      <source srcSet={srcSetP} sizes='100vw' width={sources?.[portrait_index].width} height={sources?.[portrait_index].height} />
      <img alt='' />
    </picture>
  )
}
