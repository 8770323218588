import { useEffect, useRef, useLayoutEffect, useState } from "react"
import { AnimatePresence, motion, useMotionValue, useMotionValueEvent, useSpring, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import getMediaQuery from "css/breakpoints"
import { buttonReset, buttonContrastMode } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { useExp, PAGES, CHAP_KEYS, THE_INTRODUCTION, THE_END, THE_WATCH, THE_MOVEMENT_OPENED, THE_WATCH_HUB_FEAT_3 } from "../expcontext"
import { bold } from "css/text"
import { useSwitcher } from "../switchcontext"

const Container = styled(motion.div)`
  position: absolute;
  display: flex;
  width: 100%;
  height: auto;
  inset-block-end: 10px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 30;
  justify-content: start;

  font-size: 14px;
  ${bold}

  ${getMediaQuery("m")} {
    inset-block-end: 5%;
    justify-content: center;
  }
`

const Strip = styled(motion.ul)`
  --globgap: 8px;
  --totalw: 180vw;
  --padd: 8vw;
  ${getMediaQuery("m")} {
    --totalw: min(60vw, 800px);
  }
  ${getMediaQuery("l")} {
    --totalw: min(70vw, 800px);
  }

  --lastwunit: calc((100vw - var(--padd) * 2) / 3);
  --unitw: calc(((var(--totalw)) - (var(--globgap) * 4)) / 5);

  width: 100%;
  gap: var(--globgap);
  display: grid;
  grid-auto-flow: column;
  padding-inline-start: var(--padd);
  padding-block: 0.6rem;

  scroll-snap-align: start;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding-inline-start: var(--padd);
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  & > li {
    scroll-snap-align: start;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 2rem auto;
  }

  & > li > button {
    /* padding-block-start: 2rem;
    margin-block-start: -1rem; */
  }

  & > li:nth-last-of-type(2) {
    padding-inline-end: calc(var(--padd) - var(--globgap));
  }

  & > li:nth-last-of-type(2):has(button:disabled) {
    width: calc(var(--lastwunit) * 2);
  }
  & > li:nth-last-of-type(2):has(button:disabled) + li {
    width: var(--lastwunit);
  }

  ${getMediaQuery("m")} {
    padding-inline-start: 0;
    width: calc(var(--totalw));
    padding-inline: 6px;

    & > li {
      grid-template-rows: 1fr;
    }

    & > li:nth-last-of-type(2) {
      padding-inline-end: 0;
    }

    & > li:nth-last-of-type(2):has(button:disabled) {
      width: calc(var(--unitw) * 2 + var(--globgap));
    }
    & > li:nth-last-of-type(2) + li {
      width: auto;
      transform: translateX(100%);
      transition: transform 0.8s ease-out;
    }
    & > li:nth-last-of-type(2):has(button:disabled) + li {
      width: auto;
      transform: translateX(0);
    }
  }
`

const BarCont = styled(motion.li)`
  position: relative;
  z-index: 1;

  height: 100%;
  width: var(--unitw);

  transition: width 0.8s ease-out;

  &:has(button:disabled) {
    width: calc(var(--unitw) * 2 + var(--globgap));
  }

  &.visinex span {
    transition: opacity 0.2s ease-out;
    transition-delay: 0.2s;
    opacity: 1;
  }
`

const BarEndCont = styled(motion.li)`
  position: relative;
  z-index: 0;
  height: 100%;
  width: 0;
  transition: width 0.8s ease-out;
  display: grid;

  & button {
    overflow: visible;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 5vw;
    width: 100%;
    justify-self: end;
    grid-row: 2;
  }

  & button div:first-of-type {
    grid-column: 2;
  }

  & button p {
    justify-self: end;
    margin-block-start: 0;
  }

  ${getMediaQuery("m")} {
    position: absolute;
    inset-inline-end: 0;
    & button {
      grid-row: 1;
    }
  }
`

const BarButt = styled(motion.button)`
  position: relative;
  ${buttonReset}
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: min-content auto;
  justify-items: start;
  pointer-events: all;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      html.prefers-contrast & {
        color: white !important;
        ::after {
          background: black;
        }
        & .pagination-bar {
          background: white;
        }
      }
    }
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 60px);
      left: 50%;
      position: absolute;
      top: calc(50% + 20px);
      transform: translate(-50%, -50%);
      width: calc(100% + 10px);
      z-index: -1;
    }
  }
`

const BarGround = styled(motion.div)`
  background: rgba(255 255 255 / 0.3);
  width: 100%;
  height: 4px;
  grid-row: 1;
  grid-column: 1;
  border-radius: 4px;
`

const Bar = styled(motion.div)`
  height: 100%;
  background: white;
  height: 4px;
  grid-row: 1;
  grid-column: 1;
  border-radius: 4px;

  html.prefers-contrast & {
    background: black;
  }
`

const Label = styled(motion.p)`
  user-select: none;
  text-align: start;
  line-height: 1.2rem;
  margin-block-start: 5px;

  html.prefers-contrast & {
    opacity: 1 !important;
  }
`

const EndLabel = styled.div`
  grid-column: 1;
  width: min-content;
  justify-self: end;
  align-self: start;
  margin-block-start: -0.6rem;
  margin-inline-end: 0.5rem;
  width: 18vw;
  ${getMediaQuery("m")} {
    width: 50%;
  }
`

const NextLab = styled(motion.span)`
  position: relative;
  display: inline-flex;
  margin-block-end: -1rem;
  line-height: 1rem;
  transform: translateY(0.4rem);
  opacity: 0;
  transition: opacity 0.2s ease-out;
  transition-delay: 0;
  white-space: nowrap;
  height: fit-content;
  width: fit-content;
  & svg {
    margin-inline-start: 4px;
  }

  ${getMediaQuery("m")} {
    display: none;
  }

  html.prefers-contrast & {
    color: black !important;
    background: white;
    -webkit-text-fill-color: inherit !important;
  }
`

function ProgBar({ currentPage, index, prog }) {
  const console = useConsole()

  const opac = useTransform(currentPage, c => (index <= c ? 1 : 0))
  const opacity = useSpring(opac, { stiffness: 800, damping: 100 })
  const width = useTransform(prog, [0, 1], ["0%", "100%"])

  return <Bar className='pagination-bar' style={{ opacity, width }} />
}

function FooBar({ currentPage, index }) {
  const console = useConsole()

  const opac = useTransform(currentPage, c => (index <= c ? 1 : 0))
  const opacity = useSpring(opac, { stiffness: 800, damping: 100 })

  return <Bar className='pagination-bar' style={{ opacity, width: "100%" }} />
}

function SwitchPage({ currentPage, targetPage }) {
  const console = useConsole()

  const { theEnd, scenario } = useExp()
  const { resetPhase } = useSwitcher()

  const ref = useRef()

  function onClick() {
    const phase = `${scenario.get() === 0 ? 1 : 0}.0`
    resetPhase.set(phase)
    targetPage.set(0)
  }

  function onPage(p) {
    ref.current.disabled = p < PAGES.length - 2
  }
  useMotionValueEvent(currentPage, "change", onPage)

  useEffect(() => {
    onPage(currentPage.get())
  }, [])

  return (
    <BarButt type='button' ref={ref} onClick={onClick}>
      <Bar className='pagination-bar' style={{ width: "100%" }} />
      <EndLabel>
        <Label>{theEnd[scenario.get()].switch_label}</Label>
      </EndLabel>
    </BarButt>
  )
}

function Page({ index, currentPage, targetPage }) {
  const console = useConsole()

  const { chapterLabels, videoProg, endVideoProg, targetChapter, targetChapterTransi, currentStep, currentChapter, next_label } = useExp()

  const refBtn = useRef()
  const refLi = useRef()

  const opacity = useMotionValue(index > currentPage.get() ? 0.6 : 1)

  function onClick() {
    const isWatch = currentStep.get() >= THE_WATCH && currentStep.get() <= THE_WATCH_HUB_FEAT_3
    const isMvt = currentStep.get() === THE_MOVEMENT_OPENED
    const isNext = index === PAGES.indexOf(currentChapter.get()) + 1
    if (currentChapter.get() === PAGES[index]) return
    if ((isWatch && isNext) || (isMvt && isNext)) {
      targetChapterTransi.set(PAGES[index])
    } else {
      targetChapter.set(PAGES[index])
    }
    targetPage.set(index)
  }

  function updateVisi(p) {
    if (index === p + 1) {
      refLi.current.classList.add("visinex")
    } else {
      refLi.current.classList.remove("visinex")
    }
  }

  function onCurPage(p) {
    opacity.set(index > p ? 0.6 : 1)
    refBtn.current.disabled = p === index
    updateVisi(p)
  }
  useMotionValueEvent(currentPage, "change", onCurPage)

  function onPage(p) {
    opacity.set(index > p ? 0.6 : 1)
    refBtn.current.disabled = p === index
    refBtn.current.setAttribute("aria-current", p === index)
    updateVisi(p)
  }
  useMotionValueEvent(targetPage, "change", onPage)

  useEffect(() => {
    // if (index === PAGES.indexOf(currentChapter.get())) targetChapter.set(currentChapter.get())
    refBtn.current.disabled = currentPage.get() === index
    refBtn.current.setAttribute("aria-current", currentPage.get() === index)
    updateVisi(currentPage.get())
  }, [])

  return (
    <BarCont ref={refLi} onClick={onClick}>
      <NextLab id='next'>
        {next_label}
        <Icon type='chevron' />
      </NextLab>
      <BarButt ref={refBtn} aria-describedby='next'>
        <BarGround />
        {index === PAGES.indexOf(THE_INTRODUCTION) || index === PAGES.indexOf(THE_END) ? (
          <ProgBar currentPage={currentPage} index={index} prog={index === PAGES.indexOf(THE_END) ? endVideoProg : videoProg} />
        ) : (
          <FooBar currentPage={currentPage} index={index} />
        )}
        <Label style={{ opacity }}>{chapterLabels[CHAP_KEYS[index]]}</Label>
      </BarButt>
    </BarCont>
  )
}

export default function Pagination() {
  const console = useConsole()
  const { width } = useViewport()

  const { currentStep, pagisible, currentChapter, targetChapter } = useExp()

  const currentPage = useMotionValue(-1)
  const targetPage = useMotionValue(-1)
  const totalW = useTransform(width, w => w * 1.6)
  const unitW = useTransform(totalW, tw => (tw - 8 * 4) / 5 + 8)

  const ref = useRef()

  const [isVisible, setIsVisible] = useState(pagisible.get())

  function onChapter(c) {
    const ind = PAGES.indexOf(c)
    if (ind !== -1) {
      currentPage.set(ind)
    }
  }
  useMotionValueEvent(currentStep, "change", onChapter)

  function onVisib(b) {
    setIsVisible(b)
  }
  useMotionValueEvent(pagisible, "change", onVisib)

  function onPage(p) {
    setTimeout(() => {
      ref.current?.scrollTo({
        top: 0,
        left: unitW.get() * p,
        behavior: "smooth",
      })
    }, 900)
  }
  useMotionValueEvent(targetPage, "change", onPage)

  useLayoutEffect(() => {
    const ind = PAGES.indexOf(currentChapter.get())
    targetPage.set(ind)
    onChapter(currentChapter.get())
    targetChapter.set(currentChapter.get())
  }, [])

  useEffect(() => {
    if (isVisible) {
      const elTarg = ref.current.children[currentPage.get()].offsetLeft
      ref.current.scrollTo({
        top: 0,
        left: elTarg,
        behavior: "smooth",
      })
    }
  }, [isVisible])

  return (
    <AnimatePresence>
      {isVisible ? (
        <Container
          key='pagination'
          initial={{ opacity: 0, y: 100, x: "-50%" }}
          animate={{ opacity: 1, y: 0, x: "-50%" }}
          exit={{ opacity: 0, y: 100, x: "-50%" }}
          transition={{ duration: 0.9, ease: "easeOut" }}
        >
          <Strip ref={ref}>
            {PAGES.map((_, i) => (
              <Page key={`pagina-${i.toString()}`} index={i} currentPage={currentPage} targetPage={targetPage} />
            ))}
            <BarEndCont>
              <SwitchPage currentPage={currentPage} targetPage={targetPage} />
            </BarEndCont>
          </Strip>
        </Container>
      ) : null}
    </AnimatePresence>
  )
}
