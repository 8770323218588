import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { animate, useMotionValue, useMotionValueEvent, AnimatePresence, clamp, motion, useSpring, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"

import { headline70, body50, headline50 } from "css/text"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import { useFeature } from "./featurecontext"
import { useExp } from "../expcontext"
import { PXPF, useHub } from "./hubcontext"
import FocusIndic from "./FocusIndic"
import FocusStoryLauncher from "./FocusStoryLauncher"

const Zone = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 10;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const Container = styled(motion.div)`
  position: absolute;
  inset: 0;
  ${fullGrid}
  pointer-events: none;

  grid-template-rows:
    [doc-start]
    var(--strip-height)
    [main-start]
    var(--sharemargin)
    repeat(4, [ro] minmax(0, 1fr))
    [ro main-end]
    var(--strip-height)
    [doc-end];

  width: 100%;
  height: 100%;
  z-index: 11;
`

const TextBlock = styled(motion.div)`
  position: relative;
  inset: 0;
  display: grid;
  grid-template-rows: min-content min-content;
  z-index: 2;
  filter: url(#dirblur);
  pointer-events: none;

  & > h3 {
    position: relative;
    margin-block-end: 10px;
    width: calc(100% - var(--grid-col-unit) - var(--grid-gap));
  }

  &.feat-bracelet {
    grid-column: 2 / span 6;
    grid-row: 3;
  }
  &.feat-dial {
    grid-column: 2 / span 6;
    grid-row: 3;
  }
  &.feat-design {
    grid-column: 2 / span 6;
    grid-row: 3;
  }

  ${getMediaQuery("m")} {
    & > h3 {
      width: 100%;
    }

    &.feat-bracelet {
      grid-column: 2 / span 5;
      grid-row: 4;
    }
    &.feat-dial {
      grid-column: 9 / span 5;
      grid-row: 4;
    }
    &.feat-design {
      grid-column: 2 / span 5;
      grid-row: 4;
    }
  }

  ${getMediaQuery("l")} {
    &.feat-bracelet {
      grid-column: 2 / span 4;
    }
    &.feat-dial {
      grid-column: 9 / span 4;
    }
    &.feat-design {
      grid-column: 2 / span 4;
    }
  }
`

const Title = styled(motion.h3)`
  position: relative;
  ${headline70}

  @media (max-width: 375px) {
    ${headline50}
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 20px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const Para = styled(motion.p)`
  position: relative;
  ${body50}

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 20px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

function Micro({ micro_seqloader, isOpen, index, feature_id, paragraph, title, quick_view }) {
  const console = useConsole()

  const { isFocused, targetFeatFocus } = useFeature()
  const { frameToDraw, device } = useExp()
  const { gradientProg } = useHub()

  const max = micro_seqloader.nbf * PXPF
  const startFrame = micro_seqloader.startFrame
  const axis = micro_seqloader.axis
  const isStory = !!(quick_view?.label && quick_view?.story?.length)

  const curFrameNum = useMotionValue(startFrame)
  const pointerEvents = useTransform(isOpen, b => (b ? "all" : "none"))
  const progress = useMotionValue(startFrame * PXPF)
  const gradPerc = useTransform(progress, sp => Math.max(Math.min(sp, max), 0) / max)
  const percent = useTransform(progress, sp => Math.max(Math.min(sp, max), 0) / max)

  const [isShown, setIsShown] = useState(isOpen.get())

  function onFeatFocus(b) {
    if (!b) {
      setIsShown(false)
      const speed = Math.abs(startFrame - curFrameNum.get()) / 30
      animate(progress, startFrame * PXPF, { duration: speed * 0.3 }).then(() => isOpen.set(false))
    }
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  function onPan(e, info) {
    const p = feature_id === "bracelet" ? progress.get() + info.delta[axis] : progress.get() - info.delta[axis]
    progress.set(p)
  }

  function onPanEnd(e, info) {
    const d = info.velocity[axis] <= 0 ? -1 : 1
    const dir = feature_id === "bracelet" ? d * 1 : d * -1
    const tar = dir > 0 ? max : 0
    animate(progress, tar, { duration: 0.3 })
  }

  function onOpen(b) {
    if (b) {
      setIsShown(true)
      micro_seqloader.loadFrames(device.get())
    }
  }
  useMotionValueEvent(isOpen, "change", onOpen)

  function onDevice(d) {
    if (isFocused.get() && targetFeatFocus.get() === index) {
      micro_seqloader[device.get()][curFrameNum.get()].then(im => frameToDraw.set(im))
    }
  }
  useMotionValueEvent(device, "change", onDevice)

  function onFrameNum(f) {
    micro_seqloader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(curFrameNum, "change", onFrameNum)

  function onPerc(p) {
    curFrameNum.set(Math.round(clamp(0, 1, p) * (micro_seqloader.nbf - 1)))
  }
  useMotionValueEvent(percent, "change", onPerc)

  function onGradPerc(p) {
    gradientProg.set(p)
  }
  useMotionValueEvent(gradPerc, "change", onGradPerc)

  useEffect(() => {
    gradientProg.set(gradPerc.get())
  }, [isShown])
  //
  const variants = {
    hidden: { y: "5%", opacity: 0, transition: { duration: 0.3 } },
    visible: { y: "0%", opacity: 1, transition: { duration: 0.5, delay: 0.4 } },
  }

  return (
    <AnimatePresence>
      {isShown ? (
        <>
          <Zone style={{ pointerEvents }} onPan={onPan} onPanEnd={onPanEnd} />
          <Container variants={variants} initial='hidden' animate='visible' exit='hidden'>
            <TextBlock className={`feat-${feature_id}`}>
              <Title>{title}</Title>
              {paragraph.map((p, i) => (
                <Para key={`para-${i.toString()}`}>{p}</Para>
              ))}
              {isStory ? <FocusStoryLauncher label={quick_view.label} story={quick_view.story} feature_id={feature_id} /> : null}
            </TextBlock>
          </Container>
          <FocusIndic
            progress={progress}
            max={max}
            nbf={micro_seqloader.nbf}
            startFrame={startFrame}
            curFrameNum={curFrameNum}
            feature_id={feature_id}
            onPan={onPan}
            onPanEnd={onPanEnd}
          />
        </>
      ) : null}
    </AnimatePresence>
  )
}

function Focus({ transition_seqloader, micro_seqloader, index, feature_id, label, paragraph, title, curFeature, quick_view }) {
  const console = useConsole()

  const { targetFeatFocus, isFocused } = useFeature()
  const { frameToDraw, device, dirBlurX, dirBlurY } = useExp()

  const isOpen = useMotionValue(false)
  const progress = useMotionValue(0)
  const curFrameNum = useMotionValue(0)

  const speed = transition_seqloader.nbf / 30
  const axis = micro_seqloader.axis
  const xb = axis === "x" ? 40 : 0
  const yb = axis === "y" ? 40 : 0

  function onFrameNum(f) {
    transition_seqloader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(curFrameNum, "change", onFrameNum)

  function setBlur() {
    dirBlurX.set(xb)
    dirBlurY.set(yb)
  }

  function onFeatFocus(b) {
    if (b) {
      setBlur()
      animate(dirBlurX, 0, { duration: 1 })
      animate(dirBlurY, 0, { duration: 1 })
    } else {
      setBlur()
      animate(dirBlurX, xb, { duration: 1 })
      animate(dirBlurY, yb, { duration: 1 })
    }
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  function onTarget(t) {
    if (t === index) {
      isOpen.set(true)
      animate(progress, 1, {
        duration: speed,
        onUpdate: p => curFrameNum.set(Math.round(clamp(0, 1, p) * (transition_seqloader.nbf - 1))),
      })
    }
  }
  useMotionValueEvent(targetFeatFocus, "change", onTarget)

  function onOpen(b) {
    if (!b && curFeature.get() === index) {
      animate(progress, 0, {
        duration: speed * 0.8,
        onUpdate: p => curFrameNum.set(Math.round(clamp(0, 1, p) * (transition_seqloader.nbf - 1))),
      })
    }
  }
  useMotionValueEvent(isOpen, "change", onOpen)

  return (
    <Micro
      micro_seqloader={micro_seqloader}
      isOpen={isOpen}
      index={index}
      feature_id={feature_id}
      label={label}
      paragraph={paragraph}
      title={title}
      quick_view={quick_view}
    />
  )
}

export default function FeatureFocus({ transition_seqloader, micro_seqloader, feature_id, label, paragraph, title, index, curFeature, quick_view }) {
  const console = useConsole()

  const { device } = useExp()

  useEffect(() => {
    transition_seqloader.loadFrames(device.get())
  }, [transition_seqloader, device])

  return (
    <Focus
      index={index}
      transition_seqloader={transition_seqloader}
      micro_seqloader={micro_seqloader}
      feature_id={feature_id}
      label={label}
      paragraph={paragraph}
      title={title}
      curFeature={curFeature}
      quick_view={quick_view}
    />
  )
}
