import { forwardRef, useEffect, useRef, useState } from "react"
import { animate, AnimatePresence, clamp, motion, transform, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { headline70, body50, headline50 } from "css/text"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import { SequenceProvider, useSequence } from "../sequence/seqcontext"
import Sequence from "../sequence/Sequence"
import {
  useExp,
  THE_MOVEMENT,
  THE_MOVEMENT_SPLASH,
  THE_MOVEMENT_CLOSING,
  THE_END,
  THE_MOVEMENT_CLOSED,
  THE_MOVEMENT_OPENED,
  THE_WATCH,
  PAGES,
} from "../expcontext"
import { PXPF } from "../the-watch/hubcontext"
import { HIDDEN_PHASE, useSwitcher } from "../switchcontext"
import BackgroundImg from "./BackgroundImg"
import Slider from "./Slider"
import StoryLauncher from "./StoryLauncher"
import Next from "./Next"
import Previous from "./Previous"
import { useStory } from "components/stories/context"

const Container = styled(motion.div)`
  position: absolute;
  inset: 0;
  ${fullGrid};

  grid-template-rows:
    [doc-start]
    var(--strip-height)
    [main-start]
    var(--sharemargin)
    repeat(4, [ro] minmax(0, 1fr))
    [ro main-end]
    var(--strip-height)
    [doc-end];

  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
`

const TextBlock = styled(motion.div)`
  display: grid;
  grid-template-rows: min-content min-content;
  z-index: 2;
  grid-column: 2 / span 6;
  grid-row: 3;

  transform: translateY(var(--move));

  & > h3 {
    margin-block-end: 10px;
    width: calc(100% - var(--grid-col-unit) - var(--grid-gap));
  }

  ${getMediaQuery("m")} {
    & > h3 {
      width: 100%;
    }

    grid-column: col 8 / span 5;
    grid-row: ro 2 / span 2;
    align-self: center;
    transform: translateX(var(--move));
  }

  ${getMediaQuery("l")} {
    grid-column: col 8 / span 4;
4}

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const Title = styled(motion.h3)`
  ${headline70}
  user-select: none;

  @media (max-width: 375px) {
    ${headline50}
  }

  ${getMediaQuery("l")} {
    width: calc(100% - var(--grid-col-unit) - var(--grid-gap));
  }
`

const Para = styled(motion.p)`
  ${body50}
  user-select: none;

  ${getMediaQuery("m")} {
    width: calc(100% - var(--grid-gap));
  }
`

const StoriesCont = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 5% repeat(10, 1fr) 5%;
  grid-template-rows: 5% repeat(10, 1fr) 5%;
`

const Content = forwardRef(({ data, loopOpenSeqLoader, closeSeqLoader }, animRef) => {
  const console = useConsole()

  const { frameToDraw, currentStep, closeProg, theStories, targetChapter, targetChapterTransi, device, currentInteraction, prefersReducedMotion } = useExp()
  const { resetPhase } = useSwitcher()

  const { title, paragraph } = data[THE_MOVEMENT]
  const seqloader = data[THE_MOVEMENT_SPLASH].seqloader
  const max = Math.floor(seqloader.nbf * (PXPF * 0.7))

  const animLoop = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const [isUi, setIsUi] = useState(true)
  const [dur, setDur] = useState(2)

  const curFrameNum = useMotionValue(0)
  const progress = useMotionValue(0)
  const loopProg = useMotionValue(0)

  const percent = useTransform(progress, sp => Math.max(Math.min(sp, max), 0) / max)
  const move = useTransform([percent, device], ([p, d]) => transform(p, [0, 1], d === 0 ? ["0%", "-65%"] : ["0%", "65%"]))
  const opacity = useTransform(percent, [0.3, 0.9], [1, 0])

  function onPan(e, info) {
    if (device.get() === 0) {
      animRef.current.stop()
      progress.set(progress.get() - info.delta.y)
    } else {
      animRef.current.stop()
      progress.set(progress.get() + info.delta.x)
    }
  }

  function open() {
    animRef.current?.stop()
    const speed = (seqloader.nbf - curFrameNum.get()) / 60
    if (progress.get() >= max) {
      currentStep.set(THE_MOVEMENT_OPENED)
      setIsOpen(true)
      animLoop.current = animate(loopProg, 1, { duration: 0.4, repeat: prefersReducedMotion ? 0 : Number.POSITIVE_INFINITY })
    } else {
      animate(progress, max, { duration: speed }).then(() => {
        currentStep.set(THE_MOVEMENT_OPENED)
        currentInteraction.set("movement:opened")
        setIsOpen(true)
        animLoop.current = animate(loopProg, 1, { duration: 0.4, repeat: prefersReducedMotion ? 0 : Number.POSITIVE_INFINITY })
      })
    }
  }

  function onPanEnd() {
    open()
  }

  function onClick() {
    open()
  }

  function onLoopProg(p) {
    const f = Math.round(clamp(0, 1, p) * (loopOpenSeqLoader.nbf - 1))
    loopOpenSeqLoader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(loopProg, "change", onLoopProg)

  function onFrameNum(f) {
    seqloader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(curFrameNum, "change", onFrameNum)

  function onPerc(p) {
    curFrameNum.set(Math.round(clamp(0, 1, p) * (seqloader.nbf - 1)))
  }
  useMotionValueEvent(percent, "change", onPerc)

  useEffect(() => {
    seqloader.loadFrames(device.get())
  }, [seqloader, device])

  function onCloseProg(p) {
    const f = Math.round(clamp(0, 1, p) * (closeSeqLoader.nbf - 1))
    closeSeqLoader[device.get()][f].then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(closeProg, "change", onCloseProg)

  function onPreviousClick() {
    targetChapter.set(PAGES[1])
  }

  function close() {
    setIsUi(false)
    closeProg.set(0)
    currentStep.set(THE_MOVEMENT_CLOSING)
    animRef.current?.stop()
    animLoop.current?.stop()
  }

  function goNext() {
    if (currentStep.get() < THE_MOVEMENT_OPENED) {
      open()
    } else {
      close()
    }
  }

  function onTargChapTransi(t) {
    if (t < 0) return
    if (t === THE_END) {
      close()
      targetChapterTransi.set(-1)
    }
  }
  useMotionValueEvent(targetChapterTransi, "change", onTargChapTransi)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() < THE_MOVEMENT || targetChapter.get() > THE_MOVEMENT_CLOSING) {
        animRef.current?.stop()
        animLoop.current?.stop()
        setDur(0)
        setIsUi(false)
        setIsOpen(true)
        frameToDraw.set(new Image())
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  const variants = {
    hidden: dur => ({ opacity: 0, y: "7%", transition: { duration: dur } }),
    visible: dur => ({ opacity: 1, y: "0%", transition: { duration: dur } }),
  }

  const { storyOpener } = useStory()

  function onStoryOpener(v) {
    if (v) return
    currentInteraction.set("restore")
  }
  useMotionValueEvent(storyOpener, "change", onStoryOpener)

  return (
    <>
      <AnimatePresence custom={dur}>
        <Container
          key='txtcontainer'
          variants={variants}
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ type: "tween", duration: 0.8, ease: "easeOut" }}
          style={{ "--move": move }}
        >
          <TextBlock style={{ opacity }}>
            <Title>{title}</Title>
            {paragraph.map((p, i) => (
              <Para key={`para-${i.toString()}`}>{p}</Para>
            ))}
          </TextBlock>
        </Container>
      </AnimatePresence>
      <StoriesCont>
        <AnimatePresence>
          {isOpen && isUi ? theStories.map((story, i) => <StoryLauncher key={`story.label-${i.toString()}`} index={i} {...story} />) : null}
        </AnimatePresence>
      </StoriesCont>
      <AnimatePresence>
        {isUi ? (
          <>
            <Previous onClickCB={onPreviousClick} />
            <Next key='next-chap' onClickCB={goNext} />
          </>
        ) : null}
      </AnimatePresence>
      <AnimatePresence custom={dur}>
        {!isOpen ? <Slider key='slider' percent={percent} onPan={onPan} onPanEnd={onPanEnd} onClick={onClick} /> : null}
      </AnimatePresence>
    </>
  )
})

function Mvt({ loopSeqLoader, loopOpenSeqLoader, closeSeqLoader, nbf, scenarSelected }) {
  const console = useConsole()

  const { currentStep, theMovementSequences, frameToDraw, targetChapter, currentChapter, device, prefersReducedMotion } = useExp()
  const { resetPhase } = useSwitcher()

  const { curFrameNum } = useSequence()

  const animLoop = useRef()

  const loopProg = useMotionValue(0)
  const progress = useMotionValue(0)

  const [isReadyToOpen, setIsReadyToOpen] = useState(false)

  const max = nbf * PXPF

  const percent = useTransform(progress, sp => Math.max(Math.min(sp, max), 0) / max)

  function onPerc(p) {
    curFrameNum.set(Math.round(clamp(0, 1, p) * (nbf - 1)))
  }
  useMotionValueEvent(percent, "change", onPerc)

  function onLoopProg(p) {
    const f = Math.round(clamp(0, 1, p) * (loopSeqLoader.nbf - 1))
    loopSeqLoader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(loopProg, "change", onLoopProg)

  function onCurStep(s) {
    if (s === THE_MOVEMENT) {
      animate(progress, max, { type: "tween", ease: "linear", duration: 1.6 }).then(() => {
        if (currentStep.get() !== THE_MOVEMENT) return
        currentChapter.set(THE_MOVEMENT_CLOSED)
        currentStep.set(THE_MOVEMENT_CLOSED)
        setIsReadyToOpen(true)
        animLoop.current = animate(loopProg, 1, { duration: 0.4, repeat: prefersReducedMotion ? 0 : Number.POSITIVE_INFINITY })
      })
    }
    if (s === THE_MOVEMENT_CLOSED) {
      loopProg.set(0)
      animLoop.current = animate(loopProg, 1, { duration: 0.4, repeat: prefersReducedMotion ? 0 : Number.POSITIVE_INFINITY })
      currentChapter.set(THE_MOVEMENT_CLOSED)
      setIsReadyToOpen(true)
    }
    if (s === THE_END) {
      setIsReadyToOpen(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onCurStep)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() < THE_MOVEMENT || targetChapter.get() > THE_MOVEMENT_CLOSING) {
        progress.set(0)
        frameToDraw.set(new Image())
        setIsReadyToOpen(false)
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  return (
    <AnimatePresence>
      {isReadyToOpen ? (
        <Content
          key='mvt-content'
          ref={animLoop}
          data={theMovementSequences[scenarSelected]}
          loopOpenSeqLoader={loopOpenSeqLoader}
          closeSeqLoader={closeSeqLoader}
        />
      ) : null}
    </AnimatePresence>
  )
}

export default function Movement({ scenarSelected }) {
  const console = useConsole()

  const { theMovementSequences, currentStep, device } = useExp()

  const seqloader = theMovementSequences[scenarSelected][THE_MOVEMENT].seqloader
  const loopSeqLoader = theMovementSequences[scenarSelected][THE_MOVEMENT_CLOSED].seqloader
  const loopOpenSeqLoader = theMovementSequences[scenarSelected][THE_MOVEMENT_OPENED].seqloader
  const closeSeqLoader = theMovementSequences[scenarSelected][THE_MOVEMENT_CLOSING].seqloader

  function onCurStep(s) {
    if (s > THE_WATCH) {
      loopSeqLoader.loadFrames(device.get())
      loopOpenSeqLoader.loadFrames(device.get())
      closeSeqLoader.loadFrames(device.get())
    }
  }
  useMotionValueEvent(currentStep, "change", onCurStep)

  return (
    <SequenceProvider>
      <Sequence seqloader={seqloader} />
      <BackgroundImg asset={theMovementSequences[scenarSelected][THE_MOVEMENT].background} />
      <Mvt
        nbf={seqloader.nbf}
        scenarSelected={scenarSelected}
        loopSeqLoader={loopSeqLoader}
        loopOpenSeqLoader={loopOpenSeqLoader}
        closeSeqLoader={closeSeqLoader}
      />
    </SequenceProvider>
  )
}
